import { RouteRecordRaw } from 'vue-router'
import { MODULE_SCHULUNGSORGANISATION_COLOR } from '@/modules/moduleConstants'

const VeranstaltungsorteList = () => import('@/modules/schulungsorganisation/VeranstaltungsorteList.vue')
const VeranstaltungsorteForm = () => import('@/modules/schulungsorganisation/VeranstaltungsorteForm.vue')
const AnsprechPersonVeranstaltungsorteForm = () => import('@/modules/schulungsorganisation/AnsprechPersonVeranstaltungsorteForm.vue')
const SchulungskategorienForm = () => import('@/modules/schulungsorganisation/SchulungskategorienForm.vue')
const SchulungskategorienList = () => import('@/modules/schulungsorganisation/SchulungskategorienList.vue')
const SchulungsLeistungenForm = () => import('@/modules/schulungsorganisation/SchulungsLeistungenForm.vue')
const SchulungsLeistungenList = () => import('@/modules/schulungsorganisation/SchulungsLeistungenList.vue')
const SchulungstaetigkeitenForm = () => import('@/modules/schulungsorganisation/SchulungstaetigkeitenForm.vue')
const SchulungstaetigkeitenList = () => import('@/modules/schulungsorganisation/SchulungstaetigkeitenList.vue')
const SchulungenList = () => import('@/modules/schulungsorganisation/SchulungenList.vue')
const SchulungenForm = () => import('@/modules/schulungsorganisation/SchulungenForm.vue')
const SchulungskontakteForm = () => import('@/modules/schulungsorganisation/SchulungskontakteForm.vue')
const SchulungskontakteList = () => import('@/modules/schulungsorganisation/SchulungskontakteList.vue')
const SchulungsvorlagenForm = () => import('@/modules/schulungsorganisation/SchulungsvorlagenForm.vue')
const SchulungsvorlagenList = () => import('@/modules/schulungsorganisation/SchulungsvorlagenList.vue')
const SchulungsteilnehmerForm = () => import('@/modules/schulungsorganisation/SchulungsteilnehmerForm.vue')
const SchulungsmoduleForm = () => import('@/modules/schulungsorganisation/SchulungsmoduleForm.vue')
const SchulungsmodulVorlagenForm = () => import('@/modules/schulungsorganisation/SchulungsmodulVorlagenForm.vue')
const InteressentenForm = () => import('@/modules/schulungsorganisation/InteressentenForm.vue')
const UnterschriftReferentenList = () => import('@/modules/schulungsorganisation/UnterschriftReferentenList.vue')
const UnterschriftReferentenForm = () => import('@/modules/schulungsorganisation/UnterschriftReferentenForm.vue')
const ZielgruppenForm = () => import('@/modules/schulungsorganisation/ZielgruppenForm.vue')
const ZielgruppenList = () => import('@/modules/schulungsorganisation/ZielgruppenList.vue')
const AusbildungsnachweiseZuweisungForm = () => import('@/modules/schulungsorganisation/AusbildungsnachweiseZuweisungForm.vue')
const ReglementLinksForm = () => import('@/modules/schulungsorganisation/ReglementLinksForm.vue')
const ReglementLinksList = () => import('@/modules/schulungsorganisation/ReglementLinksList.vue')
const SchulungsorganisationAusbildungsMatrixList = () => import('@/modules/schulungsorganisation/SchulungsorganisationAusbildungsMatrix.vue')
const SchulungsorganisationAusbildungsList = () => import('@/modules/schulungsorganisation/SchulungsorganisationAusbildungsList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Veranstaltungsorte
  { path: '/schulungsorganisation/veranstaltungsorte/',
    name: 'VeranstaltungsorteList',
    component: VeranstaltungsorteList,
    meta: { icon: 'mdi-map-marker-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/veranstaltungsorte/add',
    name: 'VeranstaltungsorteForm',
    component: VeranstaltungsorteForm,
    meta: { icon: 'mdi-map-marker-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/veranstaltungsorte/:id',
    name: 'VeranstaltungsorteFormUpdate',
    component: VeranstaltungsorteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-map-marker-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Ansprechpersonen Veranstaltungsorte
  { path: '/schulungsorganisation/ansprechpersonveranstaltungsorte/add',
    name: 'AnsprechPersonVeranstaltungsorteForm',
    component: AnsprechPersonVeranstaltungsorteForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  { path: '/schulungsorganisation/ansprechpersonveranstaltungsorte/:id',
    name: 'AnsprechPersonVeranstaltungsorteFormUpdate',
    component: AnsprechPersonVeranstaltungsorteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  { path: '/:modul/:model/:objectId/ansprechpersonveranstaltungsorte/add',
    name: 'AnsprechPersonVeranstaltungsorteObjektForm',
    component: AnsprechPersonVeranstaltungsorteForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  // Schulungskategorien
  { path: '/schulungsorganisation/schulungskategorien/',
    name: 'SchulungskategorienList',
    component: SchulungskategorienList,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/schulungskategorien/add',
    name: 'SchulungskategorienForm',
    component: SchulungskategorienForm,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungskategorien/:id',
    name: 'SchulungskategorienFormUpdate',
    component: SchulungskategorienForm,
    props: updateFormProps,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // SchulungsLeistung
  { path: '/schulungsorganisation/schulungsleistungen/',
    name: 'SchulungsLeistungenList',
    component: SchulungsLeistungenList,
    meta: { icon: 'mdi-checkbox-marked-circle-auto-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/schulungsleistungen/add',
    name: 'SchulungsLeistungenForm',
    component: SchulungsLeistungenForm,
    meta: { icon: 'mdi-checkbox-marked-circle-auto-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungsleistungen/:id',
    name: 'SchulungsLeistungenFormUpdate',
    component: SchulungsLeistungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-checkbox-marked-circle-auto-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Schulungstaetigkeiten
  { path: '/schulungsorganisation/schulungstaetigkeiten/',
    name: 'SchulungstaetigkeitenList',
    component: SchulungstaetigkeitenList,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  { path: '/schulungsorganisation/schulungstaetigkeiten/add',
    name: 'SchulungstaetigkeitenForm',
    component: SchulungstaetigkeitenForm,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  { path: '/schulungsorganisation/schulungstaetigkeiten/:id',
    name: 'SchulungstaetigkeitenFormUpdate',
    component: SchulungstaetigkeitenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  // Schulungen
  { path: '/schulungsorganisation/schulungen/',
    name: 'SchulungenList',
    component: SchulungenList,
    meta: { icon: 'mdi-school-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/schulungen/add',
    name: 'SchulungenForm',
    component: SchulungenForm,
    meta: { icon: 'mdi-school-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/schulungen/:id',
    name: 'SchulungenFormUpdate',
    component: SchulungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-school-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Schulungskontakte
  { path: '/schulungsorganisation/schulungskontakte/',
    name: 'SchulungskontakteList',
    component: SchulungskontakteList,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungskontakte/add',
    name: 'SchulungskontakteForm',
    component: SchulungskontakteForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungskontakte/:id',
    name: 'SchulungskontakteFormUpdate',
    component: SchulungskontakteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Schulungsvorlagen
  { path: '/schulungsorganisation/schulungsvorlagen/',
    name: 'SchulungsvorlagenList',
    component: SchulungsvorlagenList,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungsvorlagen/add',
    name: 'SchulungsvorlagenForm',
    component: SchulungsvorlagenForm,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungsvorlagen/:id',
    name: 'SchulungsvorlagenFormUpdate',
    component: SchulungsvorlagenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Schulungsteilnehmer
  { path: '/schulungsorganisation/schulungsteilnehmer/add',
    name: 'SchulungsteilnehmerForm',
    component: SchulungsteilnehmerForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungsteilnehmer/:id',
    name: 'SchulungsteilnehmerFormUpdate',
    component: SchulungsteilnehmerForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/:modul/:model/:objectId/schulungsteilnehmer/add',
    name: 'SchulungsteilnehmerObjektForm',
    component: SchulungsteilnehmerForm,
    props: true,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Schulungsmodule
  { path: '/schulungsorganisation/schulungsmodule/add',
    name: 'SchulungsmoduleForm',
    component: SchulungsmoduleForm,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/schulungsmodule/:id',
    name: 'SchulungsmoduleFormUpdate',
    component: SchulungsmoduleForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/:modul/:model/:objectId/schulungsmodule/add',
    name: 'SchulungsmoduleObjektForm',
    component: SchulungsmoduleForm,
    props: true,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Schulungsmodul Vorlagen
  { path: '/schulungsorganisation/schulungsmodulvorlagen/:id',
    name: 'SchulungsmodulVorlagenFormUpdate',
    component: SchulungsmodulVorlagenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-replace-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/:modul/:model/:objectId/schulungsmodulvorlagen/add',
    name: 'SchulungsmodulVorlagenObjektForm',
    component: SchulungsmodulVorlagenForm,
    props: true,
    meta: { icon: 'mdi-currency-eur', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Interessenten
  { path: '/schulungsorganisation/interessenten/add',
    name: 'InteressentenForm',
    component: InteressentenForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/interessenten/:id',
    name: 'InteressentenFormUpdate',
    component: InteressentenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/:modul/:model/:objectId/interessenten/add',
    name: 'InteressentenObjektForm',
    component: InteressentenForm,
    props: true,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // UnterschriftReferenten
  { path: '/schulungsorganisation/unterschriftreferenten/',
    component: UnterschriftReferentenList,
    meta: { icon: 'mdi-signature-image', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/unterschriftreferenten/add',
    name: 'UnterschriftReferentenForm',
    component: UnterschriftReferentenForm,
    meta: { icon: 'mdi-signature-image', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/unterschriftreferenten/:id',
    name: 'UnterschriftReferentenFormUpdate',
    component: UnterschriftReferentenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-signature-image', color: MODULE_SCHULUNGSORGANISATION_COLOR },
  },
  // Zielgruppen
  { path: '/schulungsorganisation/zielgruppen/',
    name: 'ZielgruppenList',
    component: ZielgruppenList,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/zielgruppen/add',
    name: 'ZielgruppenForm',
    component: ZielgruppenForm,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/zielgruppen/:id',
    name: 'ZielgruppenFormUpdate',
    component: ZielgruppenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // Ausbildungsnachweise Zuweisungen
  { path: '/schulungsorganisation/ausbildungsnachweisezuweisungen/add',
    name: 'AusbildungsnachweiseZuweisungForm',
    component: AusbildungsnachweiseZuweisungForm,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/ausbildungsnachweisezuweisungen/:id',
    name: 'AusbildungsnachweiseZuweisungFormUpdate',
    component: AusbildungsnachweiseZuweisungForm,
    props: updateFormProps,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  // ReglementLinks Zuweisungen
   { path: '/schulungsorganisation/reglementlinks/',
    name: 'ReglementLinksList',
    component: ReglementLinksList,
    meta: { icon: 'mdi-link', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/reglementlinks/add',
    name: 'ReglementLinksForm',
    component: ReglementLinksForm,
    meta: { icon: 'mdi-link', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  }, 
  { path: '/schulungsorganisation/reglementlinks/:id',
    name: 'ReglementLinksFormUpdate',
    component: ReglementLinksForm,
    props: updateFormProps,
    meta: { icon: 'mdi-link', color: MODULE_SCHULUNGSORGANISATION_COLOR }
  },
  // AusbildungsMatrix
  { path: '/schulungsorganisation/ausbildungsmatrix/',
    name: 'SchulungsorganisationAusbildungsMatrixList',
    component: SchulungsorganisationAusbildungsMatrixList,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
  { path: '/schulungsorganisation/ausbildungsliste/',
    name: 'SchulungsorganisationAusbildungsList',
    component: SchulungsorganisationAusbildungsList,
    meta: { icon: 'mdi-vector-arrange-above', color: MODULE_SCHULUNGSORGANISATION_COLOR } 
  },
]
export default routes
  

